import { z } from 'zod';

export const directPaymentSubItemsSchema = z
  .object({
    credit: z.number().optional(),
    debtConsolidation: z.number().optional(),
    paymentProtectionInsurance: z.number().optional()
  })
  .refine(data => Object.values(data).some(value => value !== undefined), {
    message: 'At least one of the fields must be defined'
  });

export const directPaymentItemSchema = z.object({
  dueDate: z.string().datetime(),
  discount: z.number(),
  totalAmountAfterDiscount: z.number(),
  totalAmount: z.number(),
  items: directPaymentSubItemsSchema
});

export const paidSchema = z.object({
  isPaid: z.literal(true)
});

export const pausedSchema = z.object({
  isPaid: z.literal(false),
  isPaused: z.literal(true),
  details: z.object({
    reason: z.string()
  })
});

export const noDiscountSchema = z.object({
  discountCategory: z.literal('NO_DISCOUNT'),
  isPaid: z.literal(false),
  details: z.object({
    payments: z.array(directPaymentItemSchema),
    amountToPay: z.number()
  })
});

export const overdueCampaignSchema = z.object({
  discountCategory: z.literal('OVERDUE_CAMPAIGN'),
  isPaid: z.literal(false),
  details: z.object({
    payments: z.array(directPaymentItemSchema),
    amountToPay: z.number(),
    amountToPayAfterDiscount: z.number(),
    discount: z.number()
  })
});

const comparisonItemSchema = z.object({
  amountToPay: z.number(),
  installmentAmount: z.number(),
  outstandingAmountAfterPayment: z.number()
});

export const resetPaybackPlanSchema = z.object({
  discountCategory: z.literal('RESET_PAYBACK_PLAN'),
  isPaid: z.literal(false),
  details: z.object({
    comparison: z.object({
      offer: comparisonItemSchema,
      current: comparisonItemSchema
    }),
    offerCost: z.number(),
    nextRepaymentDate: z.string().datetime(),
    isDirectDebitMandateSet: z.boolean()
  })
});

export const directPaymentResponseSchema = z.union([
  paidSchema,
  pausedSchema,
  noDiscountSchema,
  overdueCampaignSchema,
  resetPaybackPlanSchema
]);
